import React from 'react';
import {ReactSVG} from 'react-svg';
import {withPrefix} from 'gatsby';
import classes from './ButtonHamburger.module.scss';
import PropTypes from 'prop-types';

const ButtonHamburger = ({className, onClick: onClickHandler, status}) => {
	const classList = [
		className,
		classes.button,
		status ? classes.active : ''
	].join(' ');
	return (
		<button className={classList} onClick={onClickHandler}>
			<i/>
			<ReactSVG src={withPrefix('images/svg/hamburger-icon.svg')} />
		</button>
	)
};

ButtonHamburger.propTypes = {
	onClick: PropTypes.func.isRequired,
	status: PropTypes.bool.isRequired,
	className: PropTypes.string
};

export default ButtonHamburger;
