export const appendAdditionalScripts = () => {
	const consentTrackingScript = document.createElement('script');
	const consentTrackingScriptContent = document.createTextNode(`
    window.consent = {
      state: 'default',
      enabled: true,
      record: function (state, track) {
        if (window.consent.enabled === true) {
          window.consent.state = state;
          if (track === true) {
            window.dataLayer.push({
              'event': 'sourcepoint.consent',
              'state': state,
            });
          }
        }
      }
    };
  `);

	const consentConfigScript = document.createElement('script');
	const consentConfigScriptContent = document.createTextNode(`
    window._cmp_ = {
      config: {
        autoload: true,
        baseEndpoint: 'https://consent.eiu.com',
        propertyHref: 'http://futuretradeandinvestment.econ-asia.com',
        gdpr: {
          includeTcfApi: false
        },
        ccpa: {},
        accountId: 1747,
        privacyManager: 597025,
        events: {
          onReady: function (type) {
            if (window.consent.state !== 'visible' && window.consent.state !== 'manager') {
              window.consent.record('visible', true);
            }
          },
          onCancel: function (type) {
            window.consent.record('visible', false);
          },
          onAction: function (type, data) {
            setTimeout(function (){
              if (data.purposeConsent === 'none' && data.vendorConsent === 'none') {
                if (window.consent.state !== 'visible') {
                  window.consent.record('declined', true);
                }
              }
              else {
                window.consent.record('provided', true);
              }
            }, 500);
          },
          onSelect: function (type, choice) {
            switch (choice.type) {
              case 11: // The user has chosen the "Accept All" option in a consent message
                window.consent.record('provided', true);
                break;
              case 12: // The user has chosen to view a privacy manager (consent preferences) UI.
                window.consent.record('manager', false);
                break;
              case 13: // The user has chosen the "Reject All" message from a consent message
                window.consent.record('declined', true);
                break;
            }
          }
        }
      }
    };
  `);

	const consentScript = document.createElement('script');
	consentScript.src = '//cmp-cdn.p.aws.economist.com/latest/cmp.min.js';

	consentTrackingScript.appendChild(consentTrackingScriptContent);
	consentConfigScript.appendChild(consentConfigScriptContent);

	document.head.appendChild(consentTrackingScript);
	document.head.appendChild(consentConfigScript);
	document.head.appendChild(consentScript);

	return [
		consentTrackingScript,
		consentConfigScript,
		consentScript
	];
};

export const hasWindow = () => typeof window !== 'undefined';
