import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import HeadEconSec from '@economist/fabric-components/header/headeconsec/HeadEconSec';
import HeadAdv from '@economist/fabric-components/header/headadv/HeadAdv';
import FootEconSec from '@economist/fabric-components/footer/footeconsec/FootEconSec';
import classes from './Layout.module.scss';
import {appendAdditionalScripts} from '../../utils/utils';
import MainMenu from '../MainMenu/MainMenu';
import {withPrefix} from 'gatsby';

const HeadAdvWrap = React.memo(() => (
	<div className={classes.headAdvWrapper}>
		<HeadAdv
			textColor="#000"
			sponsoredText="supported by"
			sponsorLink="https://www.great.gov.uk/international/?utm_source=economist&utm_medium=display&utm_content=logo&utm_campaign=rtt"
			sponsorLogo={withPrefix('images/innovation-is-great.png')}
		/>
	</div>
), () => true);

const Layout = ({children, invertMenu, menuChild, menuIndentDesktop = 0}) => {
	useEffect(() => {
		const [consentTrackingScript, consentScript, consentConfigScript] = appendAdditionalScripts();

		return () => {
			document.head.removeChild(consentTrackingScript);
			document.head.removeChild(consentConfigScript);
			document.head.removeChild(consentScript);
		};
	}, []);

	return (
		<>
			<div className={classes.Layout}>
				<div className={classes.whiteBg}>
					<div className={classes.headEconSecWrapper}>
						<HeadEconSec/>
					</div>
					<HeadAdvWrap />
				</div>

				<MainMenu indentDesktop={menuIndentDesktop} inverted={invertMenu} content={menuChild}/>

				<main>{children}</main>

				<FootEconSec/>
			</div>
		</>
	)
};

Layout.propTypes = {
	menuIndentDesktop: PropTypes.number,
	menuChild: PropTypes.node,
	invertMenu: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

export default Layout
