import React, {useEffect, useState} from 'react';
import config from '../../config/config';
import DesktopMenu from './components/DesktopMenu/DesktopMenu';
import MobileMenu from './components/MobileMenu/MobileMenu';
import {useWindowWidth} from '@react-hook/window-size/throttled';
import PropTypes from 'prop-types';

const MainMenu = ({inverted = false, content, indentDesktop = 0}) => {
	const [menuState, setMenuState] = useState(false);
	const windowWidth = useWindowWidth();

	const bgColor = !menuState && inverted ? '#fff' : '#383E42';
	const iconColor = !menuState && inverted ? '#000' : '#fff';

	const setMenuStatus = (status = false) => setMenuState(status);

	useEffect(() => {
		menuState
			? document.documentElement.classList.add('overflow-hidden')
			: document.documentElement.classList.remove('overflow-hidden');
	}, [menuState, windowWidth]);

	return (
		<>
			{
				windowWidth > config.mobileWidth
					? <DesktopMenu setMenuStatusHandler={setMenuStatus}
					               indentDesktop={indentDesktop}
					               iconColor={iconColor}
					               bgColor={bgColor}
					               absolute={!!content}
					               inverted={inverted}/>
					: <MobileMenu setMenuStatusHandler={setMenuStatus}
					              iconColor={iconColor}
					              bgColor={bgColor}
					              menuStatus={menuState}
					              windowWidth={windowWidth}/>
			}

			{content ? content : null}
		</>
	)
};

MainMenu.propTypes = {
	inverted: PropTypes.bool,
	content: PropTypes.node,
	indentDesktop: PropTypes.number
};

export default MainMenu;
