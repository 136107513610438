export const ebcStories = [
	{name: "Fintech", href: "/story/fintech/"},
	{name: "Smart cities", href: "/story/smart-cities/"},
	{name: "Edtech", href: "/story/edtech/"},
	{name: "Cybersecurity", href: "/story/cybersecurity/"},
];

export default [
	{name: "Home", href: "/"},
	{name: "EIU briefing paper", href: "/briefing-paper/"},
	{
		name: "Sector stories",
		href: '/story/',
		nested: ebcStories,
	},
	{name: 'EIU infographic', href: '/infographic/'},
	{name: 'Data story', href: '/data-story/'}
];
