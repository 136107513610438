import React from 'react';
import { withPrefix } from 'gatsby';
import classes from './LinkDropdownCircular.module.scss';
import PropTypes from 'prop-types';

const LinkButtonCircular = ({file, iconDirection = 'right', icon = true, targetBlank = false, children}) => {
  const classList = [
    classes.buttonCircular,
    iconDirection === 'right' ? classes.iconRight : '',
    iconDirection === 'left' ? classes.iconLeft : '',
    iconDirection === 'down' ? classes.iconDown : ''
  ].join(' ');

  return (
    <div className={classes.dropdown}>
      {
        file && children
          ?
          targetBlank ?
            <button className={classList}>
              <div>
              {children || 'Read more'}
              {icon ? <i/> : null}
              </div>
            </button>
            : <button className={classList}>
              <div>
              {children || 'Read more'}
              {icon ? <i/> : null}
              </div>
            </button>
          : null
      }
      <div className={classes.dropdownContent}>
        <a href={withPrefix(file.href_eng)} target="_blank">{file.label_eng}</a>
        <a href={withPrefix(file.href_japan)} target="_blank">{file.label_japan}</a>
        <a href={withPrefix(file.href_korea)} target="_blank">{file.label_korea}</a>
        <a href={withPrefix(file.href_chine)} target="_blank">{file.label_chine}</a>
      </div>
    </div>
  )
};

LinkButtonCircular.propTypes = {
  children: PropTypes.node.isRequired,
  file: PropTypes.object,
  iconDirection: PropTypes.string,
  icon: PropTypes.bool,
  targetBlank: PropTypes.bool
};

export default LinkButtonCircular;
