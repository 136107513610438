export default {
	pageTitle: 'Briefing Paper | Department for International Trade',
	pageDescription: `The pandemic has transformed the global trade and investment landscape, but offers unique opportunities to agile and resilient businesses in sectors of the future.`,
	heroSecondSection: {
		position: 'left',
		main: {
			title: 'Trade and investment challenges and opportunities in the post-pandemic world',
			content: `
			<p>
				The unprecedented disruption of the global trade and investment landscape offers unique opportunities 
				to agile and resilient businesses in sectors of the future.
			</p>
		`
		},
		banner: {
			firstImg: 'images/Hero_shutterstock_775889494_compressed.jpg',
			secondImg: 'images/svg/square-gradient.svg'
		},
		files: {
			label_main: 'Select report language',
			label_eng: 'Download report',
			href_eng: 'files/EIU_Trade_and_investment_challenges_and_opportunities_2021_eng.pdf',
			label_japan: 'レポートをダウンロード',
			href_japan: 'files/EIUTradeAndInvestmentChallengesJapaneseVersion.pdf',
			label_korea: '보고서 다운로드',
			href_korea: 'files/EIUTradeAndInvestmentChallengesKoreanVersion.pdf',
			label_chine: '下载报告',
			href_chine: 'files/EIUTradeAndInvestmentChallengesChineseVersion.pdf',
			arrow: 'down',
		},
	},
	cardSectionOne: {
		position: 'left',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			title: '',
			content: `<p>When the coronavirus crisis unfolded in early 2020 it was immediately clear that it would have a 
			lasting and fundamental impact on the trade and investment landscape.</p>
			<p>Certain industries and sub-sectors have been hit particularly hard, will take longer to bounce back, and may 
			look quite different once they do so.</p>
			<p>But in many cases, the future will be shaped by the deepening and acceleration of several existing trends, 
			particularly digitalisation, datafication and the green agenda.</p>
			<p>After all, from General Motors and ARM to Microsoft and Uber, some of the best-known names in business emerged 
			during some of the steepest downturns in modern history.</p>
			<p>In analysing the effects of the global disruption, a number of themes stand out, with an overarching pattern 
			of existing trends accelerating rapidly.</p>`
		},
		additional: {
			img: 'images/eiu_briefing.jpg',
			comment: {
				text: 'Global trade dropped by estimated nearly 10% in 2020, and is expected to recover by around 7% in 2021.',
				subtext: '(The Economist Intelligence Unit, 2020).',
			}
		}
	},
	videoSectionOne: {
		videoPath: 'videos/the-economists-video-example.mp4',
		posterPath: 'images/poster-man-with-phone.jpg',
		 content: `
		 	<p>
		 		Despite the unprecedented disruption, many businesses and sectors will benefit from the acceleration of existing trends. Cybersecurity, Financial Technology (FinTech), Education Technology (EdTech), and Smart Cities are among the industries that have the potential to drive the recovery and future growth.
		 	</p>
		 `
	},
	cardSectionTwo: {
		position: 'right',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			title: 'The opportunities:',
			content: `<p class="mb-15">1) <b>Digital transformation</b> – Governments, businesses and individuals have turned 
								increasingly to digital technologies to navigate their respective lockdowns, from the online learning systems 
								provided by education technology (EdTech) providers to the cybersecurity services that have enabled many to work 
								remotely.</p> 
            		<p  class="mb-15">2) <b>Data</b> – The increased dependence on digital services has driven growth in a 
            		number of sectors and sub-sectors (e.g. financial technology (FinTech), particularly digital payments 
            		platforms), producing fresh usage and performance data that helps strengthen the case for investing in 
            		innovation.</p>
                <p>3) <b>Green agenda</b> – Sustainability is central to recovery plans in both the public and private 
                sectors. From smart city projects in transportation, energy and waste management to upskilling workers 
                for renewable industry jobs and facilitating green investments, the sustainable economy will be 
                powered by new technologies.</p>`
		},
		additional: {
			img: 'images/in_article_02_the_opportunities.jpg'
		}
	},
	cardSectionThree: {
		position: 'left',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			title: 'The challenges:',
			content: `<p class="mb-15">1) <b>The digital divide</b> – The crisis has exacerbated the effects of digital 
			inequality, with lack of access to the internet proving to be a formidable obstacle to services, learning and 
			engagement. It is also a barrier to progress in all of the sub-sectors we assess in this report.</p> 
			<p class="mb-15">2) <b>Social inequality</b> – The growing digital divide is among several factors accelerating 
			existing socioeconomic problems during the pandemic. Others include widening inequalities in terms of access to 
			healthcare and education, jobs and incomes (due partly to increased automation), and the disproportionate effect 
			of lockdown measures on the elderly, clinically vulnerable, and people with disabilities. All represent obstacles 
			to recovery and challenges for both public and private investors to address.</p>  
			<p>3) <b>Access to financing</b> – While early-stage firms already in funding cycles have often been able to 
			secure further financing, start-ups were hit hard by a slowdown in early-stage funding in the first half of 
			2020. More than 1,000 tech start-ups filed for administration, liquidation or dissolution in the UK alone in 
			the six months after the first lockdowns were implemented. Securing longer-term patient capital represents a 
			significant challenge for some companies and industry segments too.</p>`
		},
		additional: {
			img: 'images/briefing_The_challenges.jpg',
			comment: {
				text: 'The digital divide has come straight to the surface in this crisis, from its effect on education and our ability to work from home, to the elderly who can’t manage their healthcare remotely or order groceries.',
				subtext: 'Julie Snell, Chair of the Scotland 5G Centre and previously Chief Executive of Bristol is Open.',
			}
		}
	},
	cardSectionFour: {
		position: 'left',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			title: 'The pandemic is accelerating underlying trends, such as digitalisation, datafication and the green agenda.',
			content: `<p></p>`
		},
		additional: {
			img: 'images/Thumbnail_EIU_Infographic.png'
		},
		more: {
			label: 'Read More',
			link: '/infographic/'
		}
	},
	downloadSectionOne: {
		content: `
			<p>
				We have identified four key sectors that are particularly well positioned to benefit as the downturn begins to 
				subside and to lead the recovery out of it: Cybersecurity, FinTech, EdTech, and Smart Cities.
			</p>
			<p>
				In this report we take a look at the current state of play in each sector, how they have fared in the crisis, 
				and the opportunities and challenges they face in 2021 and beyond.
			</p>
		`,
		files: {
			label_main: 'Select report language',
			label_eng: 'Download report',
			href_eng: 'files/EIU_Trade_and_investment_challenges_and_opportunities_2021_eng.pdf',
			label_japan: 'レポートをダウンロード',
			href_japan: 'files/EIUTradeAndInvestmentChallengesJapaneseVersion.pdf',
			label_korea: '보고서 다운로드',
			href_korea: 'files/EIUTradeAndInvestmentChallengesKoreanVersion.pdf',
			label_chine: '下载报告',
			href_chine: 'files/EIUTradeAndInvestmentChallengesChineseVersion.pdf',
			arrow: 'down',
		},
		subcontent: `
			<p>i https://www.weforum.org/agenda/2020/08/5-things-covid-19-has-taught-us-about-inequality/</p>
			<p>ii https://www.computerweekly.com/news/252490731/Pandemic-causes-record-spike-in-collapse-of-tech-startups</p>
		`
	},
	messageWithImg: {
		hero: `<h3>5G</h3>`,
		content: `
			<p>
				The preservation of remote working and social-distancing measures in most markets in 2021, along with the continued 5G rollout, is likely to boost global demand for digital equipment and services, to the benefit of competitive manufacturers and service providers 
			</p>
		`,
		footer: `<p>(The Economist Intelligence Unit, 2020)</p>`,
		imgPath: 'images/data-img.jpg',
		addBackgroundCard: true
	}
}
