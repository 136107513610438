import React, { useEffect, useRef, useState } from 'react';
import { Link, withPrefix } from 'gatsby';
import PropTypes from 'prop-types';
import UtilSocShare from '@economist/fabric-components/utility/utilsocshare/UtilSocShare';
import ButtonHamburger from '../../../UI/ButtonHamburger/ButtonHamburger';
import config from '../../../../config/config';
import mainMenu from '../../../../constants/main-menu';
import { hasWindow } from '../../../../utils/utils';
import LinkDropdownCircular from '../../../UI/LinkDropdownCircular/LinkDropdownCircular';
import classes from './MobileMenu.module.scss';
import pageData from '../../../../../src/constants/pages/briefing-paper.js'

const MenuGeneration = ({menu, isMain = false}) => {
	const { heroSecondSection: { files } } = pageData;
	return (
		<>
			<ul className={isMain ? classes.mainMenu : classes.submenu}>
				{
					menu.map(({name, href, nested}, idx) => {
						return (
							<li key={`item-${idx}`} className={nested ? classes.submenuWrapper : null}>
								<Link to={href}
								      className={classes.navLink}
								      onClick={(e) => {
									      if (nested) e.preventDefault();
								      }}
								      onTouchStart={(e) => {
									      if (nested) e.preventDefault();
								      }}
								      getProps={({isPartiallyCurrent, isCurrent}) => {
									      let classList = [
										      classes.navLink
									      ];

									      if ((isPartiallyCurrent && href !== '/') || isCurrent) {
										      classList.push(classes.active);
									      }

									      return {
										      className: classList.join(' ')
									      };
								      }}
								      activeClassName={classes.active}>{name}</Link>

								{
									nested
										? <MenuGeneration menu={nested}/>
										: null
								}
								{
									name === 'EIU briefing paper' ?
										<div className={classes.dropdown}>
											{files ? <LinkDropdownCircular
												file={files}
												targetBlank={true}
												iconDirection={'down'}
											>
												{files.label_main}
											</LinkDropdownCircular> : null}
										</div>: null
								}

							</li>
						)
					})
				}
			</ul>
		</>
	)
};

const MobileMenu = ({bgColor, iconColor, menuStatus, setMenuStatusHandler, windowWidth}) => {
	const navRef = useRef(null);
	const [navHeight, setNavHeight] = useState({
		height: null
	});

	const updateNavHeight = () => {
		const top = navRef.current.getBoundingClientRect().top;
		setNavHeight({
			height: windowWidth <= config.mobileWidth ? `calc(100vh ${top < 0 ? `+ ${Math.abs(top)}px` : `- ${top}px`})` : 'auto'
		});
	};

	const onHamburgerClickHandler = () => {
		updateNavHeight();
		setMenuStatusHandler(!menuStatus);
	};

	useEffect(() => {
		updateNavHeight();
	}, [windowWidth]);

	const classList = [
		classes.menu,
		menuStatus ? classes.opened : classes.closed,
		classes.absolute
	].join(' ');

	return (
		<>
			<div className={classList}>

				{
					hasWindow()
						? <UtilSocShare isVertical={false} hasBorder={false} base={bgColor} iconColor={iconColor}/>
						: null
				}

				<nav
					ref={navRef}
					css={{height: navHeight.height}}
				>
					<MenuGeneration
						menu={mainMenu}
						isMain={true}/>
				</nav>

				<ButtonHamburger status={menuStatus} className={classes.hamburger} onClick={onHamburgerClickHandler}/>
			</div>
		</>
	)
};

MobileMenu.propTypes = {
	menuStatus: PropTypes.bool.isRequired,
	setMenuStatusHandler: PropTypes.func.isRequired,
	windowWidth: PropTypes.number.isRequired,
	bgColor: PropTypes.string,
	iconColor: PropTypes.string
};

export default MobileMenu;
