import React, {useEffect} from 'react';
import UtilSocShare from '@economist/fabric-components/utility/utilsocshare/UtilSocShare';
import classes from './DesktopMenu.module.scss';
import mainMenu from '../../../../constants/main-menu';
import {Link} from 'gatsby';
import {hasWindow} from '../../../../utils/utils';
import PropTypes from 'prop-types';

const MenuGeneration = ({menu, isMain = false}) => {
	return (
		<>
			<ul className={isMain ? classes.mainMenu : classes.submenu}>
				{
					menu.map(({name, href, nested}, idx) => {
						return (
							<li key={`item-${idx}`} className={nested ? classes.submenuWrapper : null}>
								<Link to={href}
								      onClick={(e) => {
									      if (nested) e.preventDefault();
								      }}
								      onTouchStart={(e) => {
									      if (nested) e.preventDefault();
								      }}
								      getProps={({isPartiallyCurrent, isCurrent}) => {
									      let classList = [
										      classes.navLink
									      ];

									      if ((isPartiallyCurrent && href !== '/') || isCurrent) {
										      classList.push(classes.active);
									      }

									      return {
										      className: classList.join(' ')
									      };
								      }}
								      activeClassName={classes.active}>
									{name}
								</Link>

								{
									nested
										? <MenuGeneration menu={nested}/>
										: null
								}
							</li>
						)
					})
				}
			</ul>
		</>
	)
};

const DesktopMenu = ({bgColor, iconColor, inverted = false, absolute = false, indentDesktop = 0, setMenuStatusHandler}) => {

	useEffect(() => setMenuStatusHandler(false), []);

	const classList = [
		classes.menu,
		absolute ? classes.absolute : '',
		inverted ? classes.inverted : ''
	].join(' ');

	return (
		<>
			<div className={classList} style={{marginBottom: `${indentDesktop}px`}}>
				{
					hasWindow()
						? <UtilSocShare isVertical={false} hasBorder={false} base={bgColor} iconColor={iconColor}/>
						: null
				}

				<nav>

					<MenuGeneration isMain={true} menu={mainMenu}/>

				</nav>
			</div>
		</>
	)
};

DesktopMenu.propTypes = {
	setMenuStatusHandler: PropTypes.func.isRequired,
	bgColor: PropTypes.string,
	iconColor: PropTypes.string,
	inverted: PropTypes.bool,
	absolute: PropTypes.bool,
	indentDesktop: PropTypes.number
};

export default DesktopMenu;
